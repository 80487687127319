$primary: #c2185b;
// Imports functions, variables, and mixins that are needed by other Bootstrap files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/grid"; // add the grid
@import "~bootstrap/scss/list-group"; // add the grid
@import "~bootstrap/scss/carousel";

// Imports fontawesome free
@import "~@fortawesome/fontawesome-free/css/all.css";

html,
body {
  height: 100%;
  background: rgba(35, 35, 45, 0.98);
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.default-bg {
  background: linear-gradient(
    159deg,
    rgba(37, 37, 50, 0.98) 0%,
    rgba(35, 35, 45, 0.98) 100%
  );
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.list-group-item,
.list-group-item:hover,
.list-group-item:active,
.list-group-item.disabled,
.list-group-item:disabled {
  background-color: transparent;
  text-decoration: none;
  border: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #495057;
}
.list-group-item:hover {
  background-color: $primary;
  color: #fff;
}
.profile-head div.mat-card-header-text {
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}
/*scrollbar css*/
::-webkit-scrollbar {
  /* width: 2px; */
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #495057;  */
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background:$primary; */
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background:$danger; */
  display: none;
}
